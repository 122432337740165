import React from 'react';
import { useTranslation } from 'react-i18next';
import icoHumanL from 'images/mascot/mascot2.png';
import LifePlanModal from '../../shared/components/modal';
import { ModalName } from '../../shared/constants/global';

export default function WelcomeModal(props) {
  const { t } = useTranslation('welcomeModal');

  const handleNextModal = () => {
    props.handleNextModal && props.handleNextModal(0)
  }

  return <LifePlanModal name={ModalName.LIFEPLAN_WELCOME}>
    <div className='popupBox'>
      <div className='popupInner'>
        <div className='lifeplanPopup'>
          <h2>{t('title')}</h2>
          <div className='popupContents'>
            <div className='popupContentsCover figBox'>
              <div className="lifeplan_welcome">
                <figure><img src={icoHumanL} className="step_image" /></figure>
                <div className="lifeplan_welcome_logo">
                  <span className="lifeplan_welcome_text1">ナビゲーター</span>
                  <br/>
                  <span className="lifeplan_welcome_text2 text">OTOUFU-X</span>
                </div>
              </div>
              <div className='popupContentsRight'>
                <div className='popupContentsTxt' dangerouslySetInnerHTML={{ __html: t('popupContentsTxt') }}></div>
                <div className='contactBtnBox marB40'>
                  <p className='alC font20 fontWB'>{t('agree')}</p>
                  <ul className='btnList'>
                    <li><a onClick={handleNextModal} className='btnSty-send'>{t('startNow')}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='cautionBox bgGray' dangerouslySetInnerHTML={{ __html: t('cautionBox', { returnObjects: true }).join('') }}></div>
          </div>
        </div>
      </div>
    </div>
  </LifePlanModal>
}
